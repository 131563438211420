//  ПРОШЛО РЕФАКТОРИНГ
.section-header {
	align-items: baseline;
	position: relative;
	color: $colorDeepOcean;

	&.section-header_inverted {
		color: $colorBrown;

		.mark-text {
			color: $colorCyan; } }

	&.section-header_st-btm-pd {
		padding-bottom: 60px; }

	&.section-header_underline {
		&:after {
			position: absolute;
			bottom: 0;
			height: 2px;
			left: $grid-gutter-width / 2;
			right: $grid-gutter-width / 2;
			content: '';
			background-color: $colorDeepOcean; } } }

.section-header__title {

	&.section-header__title_st-bt-mg {
		margin-bottom: 40px; } }

.section-header__title_sm-size {
	font-size: 30px;
	line-height: 40px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.section-header__title {

		&.section-header__title_st-bt-mg {
			margin-bottom: 25px; } } }


@include media-breakpoint-down(sm) {
	.section-header {

		&.section-header_st-btm-pd {
			padding-bottom: 50px; }

		.section-header__title_sm-size {
			font-size: 24px;
			line-height: 32px; } } }


@include media-breakpoint-down(xs);

// НАДО ОТРЕФАКТОРИТЬ
.section-header {
	&.section-header_big-top-btm-pd {
		padding: {
			top: 120px;
			bottom: 120px; } }



	&.section-header_mid-size {

		.section-header__header {
			font-size: 30px;
			line-height: 40px; } } }

.section-header__header {
	margin-bottom: 50px;
	font-size: 52px;
	line-height: 68px; }

.section-header_no-pb {
	padding-bottom: 0; }

.section-header__button {
	text-align: right; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.section-header {

		&.section-header_big-top-btm-pd {
			padding: {
				top: 100px;
				bottom: 100px; } } }

	.section-header__button {
		text-align: left; }

	.section-header_no-pb {
		padding-bottom: 0; }

	.section-header__header {
		margin-bottom: 40px;
		font-size: 42px;
		line-height: 58px; }

	.section-header__description {
		margin-bottom: 40px; }

	.section-header__button {
		order: 2; }

	.section-header_with-button-hidden-on-adaptive {

		.section-header__description {
			margin-bottom: 0; } }

	.section-header_md-underline {

		&:after {
			position: absolute;
			bottom: 0;
			height: 2px;
			left: $grid-gutter-width / 2;
			right: $grid-gutter-width / 2;
			content: '';
			background-color: $colorDeepOcean; } } }

@include media-breakpoint-down(md) {
	.section-header_md-and-lower-mid-pb {
		padding-bottom: 70px; } }

@include media-breakpoint-down(sm) {

	.section-header {

		&.section-header_big-top-btm-pd {
			padding: {
				top: 80px;
				bottom: 80px; } }

		&.section-header_mid-size {

			.section-header__header {
				font-size: 24px;
				line-height: 32px; } } }

	.section-header__header {
		font-size: 28px;
		line-height: 40px; }

	.section-header_md-and-lower-mid-pb {
		padding-bottom: 50px; } }


@include media-breakpoint-down(xs) {
	.section-header__header {
		font-size: 22px;
		line-height: 32px; } }
