.gallery-slider {
	display: none; }

.gallery-slider__item {
	overflow: hidden;
	width: 324px; }

.gallery-slider__img {
	width: 100%; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.gallery-slider {
		display: block; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
