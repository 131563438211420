.inline-items {

	&.inline-items_inverted {
		color: $colorBrown;

		.mark-text {
			color: $colorCyan; } }

	&.inline-items_header-font {
		font-family: $fontHeader;
		font-size: 32px;
		line-height: 48px;

		.inline-items__item {
			padding-right: 48px; } } }


.inline-items__item {
	display: inline; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);



@include media-breakpoint-down(sm) {

	.inline-items {

		&.inline-items_header-font {
			font-size: 20px;
			line-height: 26px; } }

	.inline-items__item {
		display: block;
		margin-bottom: 12px; } }


@include media-breakpoint-down(xs) {

	.inline-items {

		&.inline-items_header-font {
			font-size: 18px;
			line-height: 24px; } } }
