.waypoints {
	position: fixed;
	width: 40px;
	top: 120px;
	right: 20px;
	z-index: 25; }

.waypoints__el {
	display: block;
	position: relative;
	height: 40px;
	cursor: pointer;
	opacity: .7;

	&:after {
		position: absolute;
		width: 6px;
		height: 6px;
		background-color: $colorDarkBrown;
		right: 0;
		top: 17px;
		content: '';
		transition: $timeTransitionFast;
		will-change: width; }

	&.waypoints__el_active {

		&:after {
			background-color: $colorDarkSea;
			width: 28px; } } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
