.projects { // added automatically by Gulp
	color: $colorDeepOcean; }

.projects__item {}

.projects__item.projects__item_with-link {
	.projects__img-cont {
		cursor: pointer;
		&.hovered {
			&:after {
				opacity: .6; } } }
	.project__title {
		cursor: pointer; } }

.projects__img-cont {
	position: relative;
	display: block;
	width: 100%;
	height: 163px;
	overflow: hidden;
	font-size: 0;
	margin-bottom: 40px;

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: '';
		background-color: $colorDarkSea;
		opacity: 0;
		transition: .7s; }

	//&.hovered
	//	&:after
 }	//		opacity: .6

.projects__img {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%); }

.projects__hashtags {
	margin-bottom: 10px; }

.projects__hashtag_with-link {
	cursor: pointer; }

.projects__hashtag {
	position: relative;
	padding-left: 16px;
	padding-right: 30px;
	color: $colorDeepOcean;

	&:before {
		position: absolute;
		left: 0;
		content: '#'; }

	&:hover {

		.projects__hashtag-underline {
			background-size: 100% 100%; } } }

.projects__hashtag-underline {
	width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size .3s; }

.project__title {
	font-family: $fontHeader;
	display: block;
	margin-bottom: 50px;
	font-size: 20px;
	line-height: 26px;

	&.hovered {
		.project__title-underline {
			background-size: 100% 100%; } } }

.project__title-underline {
	width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size 1s; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.projects {
		padding-top: 60px; }

	.projects__img-cont {
		margin-bottom: 20px; } }

@include media-breakpoint-down(sm) {
	.projects {}

	.projects__img-cont {
		height: 203px;
		margin-bottom: 30px; }
	.project__title {
		margin-bottom: 60px; } }

@include media-breakpoint-down(xs) {
	.projects__img-cont {
		height: 160px; } }
