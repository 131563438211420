.page-header {} // added automatically by Gulp

.page-header_main-page {
	color: $colorBrown;
	margin-bottom: 158px;
	transition: $timeTransitionSlow ease;
	opacity: 1;

	&.disappeared {
		opacity: 0; } }

.page-header_inner-page {
	color: $colorDeepOcean;
	margin-bottom: 50px; }

@include media-breakpoint-down(md) {

	.page-header_main-page {
		margin-bottom: 120px;

		h1 {
			font-size: 42px;
			line-height: 58px; } }

	.page-header_inner-page {
		margin-bottom: 35px; } }

@include media-breakpoint-down(sm) {

	.page-header_main-page {
		margin-bottom: 80px;

		h1 {
			font-size: 28px;
			line-height: 40px; } }

	.page-header_inner-page {
		margin-bottom: 25px; } }

@include media-breakpoint-down(xs) {

	.page-header_main-page {

		h1 {
			font-size: 22px;
			line-height: 32px; } } }
