.page-header-section { // added automatically by Gulp
	position: relative; }

.page-header-section_main-page {
	background-color: $colorDeepOcean;
	padding-top: 240px; }

.page-header-section_inner-page {
	padding-top: 250px; }

@include media-breakpoint-down(md) {
	.page-header-section_main-page {
		padding-top: 140px; }
	.page-header-section_inner-page {
		padding-top: 140px; } }

@include media-breakpoint-down(sm) {
	.page-header-section_inner-page {
		padding-top: 140px; } }


@include media-breakpoint-down(xs);
