.testimonials { // added automatically by Gulp
	color: $colorDeepOcean; }

.testimonials__item {
	margin-bottom: 50px; }

.testimonials__img-cont {
	position: relative;
	width: 80px;
	height: 80px;
	overflow: hidden;
	border-radius: 50%;
	margin-bottom: 40px; }

.testimonials__img {
	width: 100%; }

.testimonials__title {
	margin-bottom: 20px;
	min-height: 78px; }

.testimonials__description {
	margin-bottom: 15px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.testimonials__img-cont {
		margin-bottom: 30px; } }


@include media-breakpoint-down(sm) {
	.testimonials__title {
		min-height: 10; } }


@include media-breakpoint-down(xs);
