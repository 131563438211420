.projects-geography {
	position: relative;
	overflow: hidden; }

.projects-geography__section-header {
	margin-bottom: 140px; }

.projects-geography__text-items {
	position: relative;
	z-index: 2; }

.projects-geography__text-item {
	margin-bottom: 10px; }

.projects-geography__text-wrap {

	&:hover,
	&.hovered {
		color: $colorCyan;
		transition: $timeTransitionFast; } }

.projects-geography__map-wrap {
	padding-top: 41.1%;
	margin-top: -200px;
 }	//border: 1px dashed red

.projects-geography__map {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 30px;
	//border: 1px dashed green
	background-image: url('../assets/svg/russia.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain; }

.projects-geography__map-point {
	position: absolute;
	width: 24px;
	height: 24px;

	&:hover {
		z-index: 3; }

	&:after {
		position: absolute;
		width: 24px;
		height: 24px;
		top: 0;
		left: 0;
		border-radius: 50%;
		background-color: $colorCyan;
		content: '';
		transition: $timeTransitionFast; }

	&:hover,
	&.hovered {
		z-index: 2;

		&:after {
			transform: scale(1.2); }

		.projects-geography__map-point-text {
			opacity: 1;
			top: -28px;
			transform: translateX(-50%) scale(1);
			transition: opacity $timeTransitionFast, top $timeTransitionFast, transform $timeTransitionFast step-start; } }

	&.projects-geography__map-point_barnaul {
		top: 34%;
		left: 90%; }

	&.projects-geography__map-point_kemerovo {
		top: 30%;
		left: 30%; }

	&.projects-geography__map-point_sochi {
		top: 40%;
		left: 40%; }

	&.projects-geography__map-point_vladivostok {
		top: 50%;
		left: 50%; }

	&.projects-geography__map-point_moscow {
		top: 70%;
		left: 70%; }

	.projects-geography__map-point-text {
		position: absolute;
		overflow: hidden;
		top: -10px;
		left: 50%;
		transform: translateX(-50%) scale(0);
		background-color: $colorBrown;
		color: $colorCyan;
		white-space: nowrap;
		padding: 2px 6px;
		height: auto;
		opacity: 0;
		transition: opacity $timeTransitionFast, top $timeTransitionFast, transform $timeTransitionFast step-end; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.projects-geography__section-header {
		margin-bottom: 40px; }

	.projects-geography__text-item {
		display: inline-block;
		margin-right: 20px; }

	.projects-geography__map-wrap {
		padding-top: 52%;
		margin-top: 0; }

	.projects-geography__map {
		left: 30px; } }

@include media-breakpoint-down(sm) {
	.projects-geography {
		min-height: 380px; }

	.projects-geography__map-point {
		display: none; }

	.projects-geography__text-item {
		display: block; }

	.projects-geography__map-wrap {
		position: absolute;
		top: 20px;
		left: 50px;
		width: 650px;
		height: 366px;
		overflow: hidden;
		flex-shrink: 0; }

	.projects-geography__map {
		width: 590px;
		height: 366px; } }

@include media-breakpoint-down(xs) {

	.projects-geography__map-wrap {
		width: 500px; } }
