.button-square {
	display: inline-block;
	line-height: 80px;
	text-align: center;
	font-size: 20px;
	padding: 0 20px;
	font-family: $fontHeader;
	color: $colorBrown;
	background-color: $colorBlueCyan;
	transition: background-color $timeTransitionMid;
	cursor: pointer;
	white-space: nowrap;
	//background-image: url('/assets/svg/dots-preloader.svg')

	&:hover {
		background-color: $colorDarkSea; }

	// Модификаторы
	&_width_full-width {
		display: block;
		width: 100%; }

	&_bizy {
		color: transparent;
		background-image: url('/assets/svg/dots-preloader-brown.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 80px; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.button-square {
		line-height: 60px; } }

@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
