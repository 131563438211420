.picture {
	max-width: 100%;
	overflow: hidden; }

.picture__img {
	width: 100%; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
