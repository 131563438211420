.section {}

.section_st-top-btm-pd {
	padding: {
		top: 100px;
		bottom: 100px; } }

.section_st-top-pd {
	padding: {
		top: 100px; } }

.section_st-btm-pd {
	padding: {
		bottom: 100px; } }

.section_sm-top-btm-pd {
	padding: {
		top: 60px;
		bottom: 60px; } }

.section_sm-top-pd {
	padding: {
		top: 60px; } }

.section_sm-btm-pd {
	padding: {
		bottom: 60px; } }

.section_bg-dark {
	background-color: $colorDeepOcean; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.section_st-top-btm-pd {
		padding: {
			top: 80px;
			bottom: 80px; } }

	.section_st-top-pd {
		padding: {
			top: 80px; } }

	.section_st-btm-pd {
		padding: {
			top: 80px; } }

	.section_sm-top-btm-pd {
		padding: {
			top: 50px;
			bottom: 50px; } }

	.section_sm-top-pd {
		padding: {
			top: 50px; } }

	.section_sm-btm-pd {
		padding: {
			bottom: 50px; } } }


@include media-breakpoint-down(sm) {
	.section_sm-top-btm-pd {
		padding: {
			top: 40px;
			bottom: 40px; } }

	.section_sm-top-pd {
		padding: {
			top: 40px; } }

	.section_sm-btm-pd {
		padding: {
			bottom: 40px; } } }


@include media-breakpoint-down(xs);
