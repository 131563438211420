.header-bg-animation {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 1;
	transition: 1s;
	overflow: hidden;
	//background-image: linear-gradient(155deg,$colorDeepOcean 0%,$colorDeepOcean 57%,$colorDarkSea 57%,$colorDarkSea 63%,$colorBlueCyan 63%,$colorBlueCyan 65%,$colorDeepOcean 65%,$colorDeepOcean 68.5%, $colorGreenCyan 68.5%, $colorGreenCyan 72%, $colorDeepOcean 72%, $colorDeepOcean 100%)

	&.disappeared {
		opacity: 0;
		//background-image: linear-gradient(155deg,$colorDeepOcean 0%,$colorDeepOcean 0%,$colorDarkSea 0%,$colorDarkSea 63%,$colorBlueCyan 63%,$colorBlueCyan 70%,$colorDeepOcean 70%,$colorDeepOcean 70%, $colorGreenCyan 70%, $colorGreenCyan 100%, $colorDeepOcean 100%, $colorDeepOcean 100%)

		.header-bg-animation__lines {
			height: 70px;
			opacity: .5;

			&:before {
				top: -1100px;
				height: 1100px; }

			&:after {
				height: 550px; } } } }

$bgAnimSpeed: 2.4s;

.header-bg-animation__lines {
	position: absolute;
	height: 24px;
	width: 200%;
	left: -300px;
	top: 380px;
	background-color: $colorBlueCyan;
	transform: rotate(-25deg);
	transition: $bgAnimSpeed;
	opacity: 1;

	&:before {
		position: absolute;
		right: 0;
		left: 0;
		top: -70px;
		background-color: $colorDarkSea;
		height: 70px;
		content: '';
		transition: $bgAnimSpeed; }

	&:after {
		position: absolute;
		right: 0;
		left: 0;
		top: 69px;
		background-color: $colorGreenCyan;
		height: 43px;
		content: '';
		transition: $bgAnimSpeed; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
