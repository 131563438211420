.header-vantages { // added automatically by Gulp
	font-family: $fontHeader;
	color: $colorDeepOcean;
	padding-top: 50px;
	padding-bottom: 20px;
	font-size: 20px;
	line-height: 26px; }

.header-vantages__item {
	margin-bottom: 30px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
