.paragraph { // added automatically by Gulp
	color: $colorDeepOcean; }

.paragraph_mb-lg {
	margin-bottom: 100px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.paragraph_mb-lg {
		margin-bottom: 80px; } }


@include media-breakpoint-down(sm) {
	.paragraph_mb-lg {
		margin-bottom: 60px; } }


@include media-breakpoint-down(xs);
