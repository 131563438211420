.color {}

.color__preview {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	display: inline-block;
	line-height: 70px;
	vertical-align: top; }

.color__preview-text {
	line-height: 70px;
	display: inline-block;
	vertical-align: top; }

.color__font_cyan {
	color: $colorCyan; }

.color__font_brown {
	color: $colorBrown; }

.color__font_deep-ocean {
	color: $colorDeepOcean; }

.color__font_blue-cyan {
	color: $colorBlueCyan; }

.color__dark-sea {
	color: $colorDarkSea; }

.color__font_green-cyan {
	color: $colorGreenCyan; }

.color__font_dark-brown {
	color: $colorDarkBrown; }

.color__font_red {
	color: $colorRed; }


.color__bg_cyan {
	background-color: $colorCyan; }

.color__bg_brown {
	background-color: $colorBrown; }

.color__bg_deep-ocean {
	background-color: $colorDeepOcean; }

.color__bg_blue-cyan {
	background-color: $colorBlueCyan; }

.color__bg_dark-sea {
	background-color: $colorDarkSea; }

.color__bg_green-cyan {
	background-color: $colorGreenCyan; }

.color__bg_dark-brown {
	background-color: $colorDarkBrown; }

.color__bg_red {
	background-color: $colorRed; }
