.popup {
	width: 568px;
	min-height: 320px;
	text-align: left;
	background-color: $colorBlueCyan;
	padding: {
		top: 95px;
		bottom: 95px;
		left: 60px;
		right: 60px; } }

.popup__icon {
	margin-bottom: 20px; }

.popup__title {
	font-family: $fontHeader;
	margin-bottom: 30px;
	line-height: 52px; }

.popup_cta {}


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs) {

	.popup {
		padding: {
			left: 20px;
			right: 20px; } } }
