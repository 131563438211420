.tags-and-date-wrap {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm) {
	.tags-and-date-wrap {
		flex-direction: column; } }


@include media-breakpoint-down(xs);
