.debug-monitor {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	background-color: rgba(255, 0, 0, 0.5);
	padding: 2px 5px;
	display: none;
	color: white; }

.debug-monitor__active {
	display: block; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
