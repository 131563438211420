.strange-pic {
	margin-top: 120px;
	width: 80%; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.strange-pic {
		margin-top: 0;
		width: 100%; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
