.preloader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	background-color: $colorDeepOcean;
	opacity: 0;
	transition: opacity $timeTransitionMid $timeTransitionMid, z-index $timeTransitionMid $timeTransitionMid step-end;

	.preloader__img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 160px;
		height: 66px;
		background-image: url('../assets/svg/preloader.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0;
		transition: opacity $timeTransitionMid; }

	&.active {
		z-index: 10000;
		opacity: 1;

		.preloader__img {
			opacity: 1; } } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
