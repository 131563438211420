.index-page {

	.top-directions {
		display: none; } }

.top-menu {

	&.scrolled {

		.top-directions {
			transform: translateY(-102%); } } }


.top-directions {
	background-color: $colorDeepOcean;
	color: $colorBrown;
	position: relative;
	transition: $timeTransitionMid;
	transform: translateY(0);

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -3000px;
		right: -3000px;
		content: '';
		background-color: $colorDeepOcean; }

	.top-direction__items {
		display: flex;
		padding: 30px 0 32px;
		position: relative;
		z-index: 2; }

	.top-direction__item {
		margin-right: 40px; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.top-directions {
		display: none; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
