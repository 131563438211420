// Обертка для всего приложения.
// За ее пределами могут находится разнообразные скрипты
// и элементы, не относящиеся к приложению.
.wrap {} // added automatically by Gulp

.wrap__section-content {
	margin: auto;
	max-width: 1400px; }

.wrap__section-content_mid-top-padding {
	padding-top: 100px; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.wrap__section-content_mid-top-padding {
		padding-top: 70px; } }


@include media-breakpoint-down(sm) {
	.wrap__section-content_mid-top-padding {
		padding-top: 60px; } }


@include media-breakpoint-down(xs);
