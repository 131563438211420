@mixin clearfix() {
  &:after, &:before {
    content: " ";
    display: table; }
  &:after {
    clear: both;
    *zoom: 1; } }

@mixin overlay($bg-color, $z-index:10) {
  position: absolute;
  z-index: $z-index;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: $bg-color; }
