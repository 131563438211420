.our-clients { // added automatically by Gulp
	color: $colorBrown;
	padding-bottom: 120px; }

.our-clients__item {
	display: inline;
	padding-right: 48px;
	font-family: $fontHeader;
	font-size: 32px;
	line-height: 48px;

	&.our-clients__item_marked {
		color: $colorCyan; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.our-clients {
		padding-bottom: 100px; } }


@include media-breakpoint-down(sm) {
	.our-clients {
		padding-bottom: 80px; }
	.our-clients__item {
		font-size: 20px;
		line-height: 26px;
		display: block;
		margin-bottom: 12px; } }


@include media-breakpoint-down(xs) {
	.our-clients__item {
		font-size: 18px;
		line-height: 24px; } }
