.publication-content-item {
	margin-bottom: 50px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.publication-content-item {
		margin-bottom: 40px; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
