//// Шрифты
$fontHeader: 'DrukWideMedium', 'sans-serif';
$fontText: 'RubicMedium', 'sans-serif';

// Цвета, из дизайн-системы
$colorCyan: #1D949C;
$colorBrown: #E2DED3;
$colorDeepOcean: #03313F;
$colorBlueCyan: #158AA3;
$colorDarkSea: #0A546B;
$colorGreenCyan: #015F61;
$colorDarkBrown: #BDBAAF;
$colorRed: #A32F15;

// Время анимации
$timeTransitionFast: .3s;
$timeTransitionMid: .7s;
$timeTransitionSlow: 1s;
