.logo-pronin { // added automatically by Gulp
	display: inline-block;
	cursor: pointer;

	svg {
		path {
			transition: .3s;
			fill: $colorBrown; } }

	&:hover {
		svg {
			path {
				transition: .3s;
				fill: $colorCyan; } } } }
