* {
	box-sizing: border-box; }

body {
	background-color: $colorBrown;
	color: $colorDeepOcean; }

textarea {
	resize: vertical; }

input, textarea {
	background-color: transparent;
	border: none;
	outline: none;
	display: block;
	width: 100%; }

a {
	text-decoration: none;
	color: inherit;

	&:hover,
	&:active,
	&:visited {
		color: inherit; } }

p {
	margin-bottom: 20px; }

.color__bg_deep-ocean {
	color: $colorBrown; }

.color_cyan {
	color: $colorCyan; }

.color_deep-ocean {
	color: $colorDeepOcean; }

.line {
	margin: 0;
	height: 3px;
	background-color: black; }

.round-elem {
	border-radius: 50%; }

.no-bg {
	background: none; }

.text-align-right {
	text-align: right; }

.transparent {
	opacity: 0; }

.mb-mid-md {
 }	//

.nowrap {
	white-space: nowrap; }

.v-align_baseline {
	vertical-align: baseline; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.pt-mid-md {
		padding-top: 50px; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);



// START Стили, регулирующие верхние и нижние отступы.

// mv - margin vertical
// mb - margin bottom
// bg - большой отступ. Используется для элементов, которые расположены перед заголовками
// st - стандартный отступ. Используется:
//  	# для разделения разнородных групп элементов внутри контента блока
//  	# после заголовков внутри блока
// sm - маленький отступ. Исползуется для разделения однородных элементов.

.mv_bg {
	margin: {
		top: 70px	!important;
		bottom: 70px	!important; } }

.mb_bg {
	margin-bottom: 70px	!important; }

.mv_st {
	margin: {
		top: 50px	!important;
		bottom: 50px	!important; } }


.mt_st {
	margin-top: 50px	!important; }

.mb_st {
	margin-bottom: 50px	!important; }

.mv_sm {
	margin: {
		top: 20px	!important;
		bottom: 20px	!important; } }

.mb_sm {
	margin-bottom: 20px	!important; }

.mb_xsm {
	margin-bottom: 8px !important; }

@include media-breakpoint-down(md) {
	.mv_bg {
		margin: {
			top: 55px	!important;
			bottom: 55px	!important; } }

	.mb_bg {
		margin-bottom: 55px	!important; }

	.mv_st {
		margin: {
			top: 40px	!important;
			bottom: 40px	!important; } }

	.mt_st {
		margin-top: 40px	!important; }

	.mb_st {
		margin-bottom: 40px	!important; } }

@include media-breakpoint-down(sm) {
	.mv_bg {
		margin: {
			top: 45px	!important;
			bottom: 45px	!important; } }

	.mb_bg {
		margin-bottom: 45px	!important; }

	.mv_st {
		margin: {
			top: 35px	!important;
			bottom: 35px	!important; } }

	.mt_st {
		margin-top: 35px	!important; }

	.mb_st {
		margin-bottom: 35px	!important; }

	.mv_sm {
		margin: {
			top: 15px	!important;
			bottom: 15px	!important; } }

	.mb_sm {
		margin-bottom: 15px	!important; } }

// END Стили, регулирующие верхние и нижние отступы.
