.side-menu {
	position: fixed;
	z-index: 9999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	font-size: 32px;
	line-height: 40px;
	background-color: $colorDarkSea;
	color: $colorCyan;
	font-family: $fontHeader;
	text-align: left;
	opacity: 0;
	overflow-y: scroll;
	transform: translateY(-100%);
	transition: transform $timeTransitionFast ease, opacity $timeTransitionFast step-end; }

.side-menu_active {
	opacity: 1;
	transform: translateY(0);
	transition: transform $timeTransitionFast ease, opacity $timeTransitionFast step-start; }

.side-menu__nav-item {
	margin-bottom: 10px; }

.side-menu__nav {
	margin-bottom: 90px; }

.side-menu__cta-cont {
	line-height: 25px;
	margin-bottom: 15vh;

	button {
		font-size: 20px;
		line-height: 26px; }

	div {
		margin-bottom: 10px; } }

.side-menu__contacts-item {
	font-size: 20px;
	line-height: 26px;
	margin-bottom: 15px; }

.side-menu__close-button {
	position: fixed;
	top: 22px;
	right: 22px;
	width: 30px;
	height: 30px;
	cursor: pointer;
	&:after {
		position: absolute;
		width: 20px;
		height: 2px;
		background-color: $colorCyan;
		transform: rotate(45deg);
		content: "";
		top: 13px;
		left: 4px; }
	&:before {
		position: absolute;
		width: 20px;
		height: 2px;
		background-color: $colorCyan;
		transform: rotate(135deg);
		content: "";
		top: 13px;
		left: 4px; } }

.side-menu__content {
	max-width: 700px;
	margin: 15vh auto;
	text-align: left; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm) {

	.side-menu {
		font-size: 20px;
		line-height: 26px; }

	.side-menu__content {
		padding: 0 15px;
		max-width: 500px; }

	.side-menu__nav {
		margin-bottom: 50px; }


	.side-menu__cta-cont {
		margin-bottom: 5vh; } }

@include media-breakpoint-down(xs);
