.hashtags {}

.hashtags__hashtag {
	position: relative;
	margin: 0 10px 0 15px;

	&:before {
		position: absolute;
		left: -15px;
		content: '#'; } }

.hashtags_margin-right_lg {
	margin-right: 70px; }

.hashtags_margin-bottom_sm {
	margin-bottom: 10px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
