.big-business-nav { // added automatically by Gulp
	padding-bottom: 68px;
	opacity: 1;
	transform: translateY(0);
	transition: $timeTransitionMid;

	&.disappeared {
		opacity: 0;
		transform: translateY(20px); } }

.big-business-nav__item {
	margin-bottom: 8px; }

@include media-breakpoint-down(md) {
	.big-business-nav__item {
		margin-bottom: 15px; } }

@include media-breakpoint-down(sm) {
	.big-business-nav__item {
		margin-bottom: 10px; } }

@include media-breakpoint-down(xs);
