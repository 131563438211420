.ui-kit {}

.ui-kit__section-description {
	background-color: $colorRed;
	color: $colorBrown;
	border-top: 5px dashed black;
	padding: {
		top: 25px;
		bottom: 10px; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
