.prom-equipment { // added automatically by Gulp
	padding-top: 40px; }

.prom-equipment__button-col {
	padding-top: 50px; }

.prom-equipment__item {
	display: flex;
	position: relative;
	cursor: pointer;
	padding: {
		top: 40px;
		bottom: 40px; }

	&:hover {

		.prom-equipment__item-img-cont {

			&:after {
				opacity: .6; } }

		.link__underline {
			background-size: 100% 100%; } }


	&:first-child {
		padding-top: 0; }

	&:after {
		position: absolute;
		bottom: 0;
		height: 2px;
		left: 0;
		right: 0;
		content: '';
		background-color: $colorDeepOcean; }

	&:last-child {
		padding-bottom: 0;
		&:after {
			display: none; } } }

.prom-equipment__item_align-items-center {
	align-items: center;

	.prom-equipment__item-text-container {
		padding-top: 0; }

	.prom-equipment__item-title {
		margin-bottom: 0; } }

.prom-equipment__item-img-cont {
	position: relative;
	width: 160px;
	height: 160px;
	overflow: hidden;
	flex-shrink: 0;
	margin-right: 80px;

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: '';
		opacity: 0;
		background-color: $colorDarkSea;
		transition: .7s; } }

.prom-equipment__item-img {
	position: absolute;
	height: 100%;
	left: 50%;
	transform: translateX(-50%); }

.prom-equipment__item-text-container {
	padding-top: 50px; }

.prom-equipment__item-title {
	font-family: $fontHeader;
	margin-bottom: 15px; }

.prom-equipment__item-description {}

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.prom-equipment {
		padding-top: 30px; }

	.prom-equipment__item {
		padding: {
			top: 30px;
			bottom: 30px; } }

	.prom-equipment__item-img-cont {
		margin-right: 50px; } }

@include media-breakpoint-down(sm) {
	.prom-equipment {
		padding-top: 20px; }

	.prom-equipment__item {
		display: block;
		padding: {
			top: 20px;
			bottom: 20px; } }

	.prom-equipment__item_align-items-center {

		.prom-equipment__item-text-container {
			padding-top: 20px; } }

	.prom-equipment__item-img-cont {
		width: 120px;
		height: 120px; }

	.prom-equipment__item-text-container {
		padding-top: 20px; } }

@include media-breakpoint-down(xs);
