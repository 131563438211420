// шрифт заголовков
@font-face {
	font-family: 'DrukWideMedium';
	src : url('../fonts/DrukWideMediumCy.woff') format('woff'), url('../fonts/DrukWideMediumCy.ttf') format('truetype');
	font-weight: normal;
	font-style: normal; }

// шрифт абзацев
@font-face {
	font-family: 'RubicMedium';
	src : url('../fonts/Rubikmedium.woff2') format('woff2'), url('../fonts/Rubikmedium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal; }


// стили //
body, p {
	font-family: $fontText;
	font-size: 16px;
	line-height: 26px; }

.font-article {
	font-family: $fontText; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.font-header {
	font-family: $fontHeader; }

h1,
.h1 {
	font-size: 68px;
	line-height: 92px; }

h2,
.h2 {
	font-size: 52px;
	line-height: 68px; }

h3,
.h3 {
	font-size: 32px;
	line-height: 40px; }

h4,
.h4 {
	font-size: 20px;
	line-height: 26px; }

h5, h6,
.h5, .h6 {
	font-size: 15px;
	line-height: 17px; }


@include media-breakpoint-down(lg) {

	h1, .h1 {
		font-size: 52px;
		line-height: 68px; }

	h2, .h2 {
		font-size: 42px;
		line-height: 58px; }

	h3, .h3 {
		font-size: 32px;
		line-height: 40px; }

	h4, .h4 {
		font-size: 20px;
		line-height: 26px; }

	h5, h6, .h5, .h6 {
		font-size: 15px;
		line-height: 17px; } }


@include media-breakpoint-down(md) {

	h1, .h1 {
		font-size: 52px;
		line-height: 68px; }

	h2, .h2 {
		font-size: 42px;
		line-height: 58px; }

	h3, .h3 {
		font-size: 32px;
		line-height: 40px; }

	h4, .h4 {
		font-size: 20px;
		line-height: 26px; }

	h5, h6, .h5, .h6 {
		font-size: 15px;
		line-height: 17px; } }


@include media-breakpoint-down(sm) {

	h1, .h1 {
		font-size: 28px;
		line-height: 40px; }

	h2, .h2 {
		font-size: 28px;
		line-height: 40px; }

	h3, .h3 {
		font-size: 24px;
		line-height: 32px; }

	h4, .h4 {
		font-size: 20px;
		line-height: 26px; }

	h5, h6, .h5, .h6 {
		font-size: 15px;
		line-height: 17px; } }


@include media-breakpoint-down(xs) {

	h1, h1 {
		font-size: 22px;
		line-height: 32px; }

	h2, .h2 {
		font-size: 22px;
		line-height: 32px; }

	h3, .h3 {
		font-size: 20px;
		line-height: 26px; }

	h4, .h4 {
		font-size: 18px;
		line-height: 24px; }

	h5, h6, .h5, .h6 {
		font-size: 15px;
		line-height: 17px; } }


