.section-contacts { // added automatically by Gulp
	padding: {
		top: 80px;
		bottom: 80px; }
	align-items: baseline;
	color: $colorBrown; }

.section-contacts__adress {
	font-family: $fontHeader;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 10px; }

.section-contacts__phones {
	margin-bottom: 40px; }

.section-contacts__phone {
	white-space: normal;
	font-family: $fontHeader;
	font-size: 32px;
	line-height: 40px;
	color: $colorCyan;
	margin-bottom: 40px; }

.section-contacts__map-link {
	margin-bottom: 25px;

	span {
		cursor: pointer;
		border-bottom: 2px solid $colorBrown;

		&:hover {
			color: $colorDarkBrown;
			border-color: $colorDarkBrown; } } }

.section-contacts__timetable {
	margin-bottom: 25px; }

.section-contacts__email {

	a {
		cursor: pointer;
		border-bottom: 2px solid $colorBrown;

		&:hover {
			color: $colorDarkBrown;
			border-color: $colorDarkBrown; } } }



@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.section-contacts__map-link,
	.section-contacts__timetable {
		margin-bottom: 18px; } }


@include media-breakpoint-down(sm) {
	.section-contacts__adress,
	.section-contacts__phone {
		font-size: 24px;
		line-height: 32px; } }

@include media-breakpoint-down(xs) {
	.section-contacts__adress,
	.section-contacts__phone {
		font-size: 20px;
		line-height: 26px; } }
