//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 401px,
        md: 768px,
        lg: 1101px,
        xl: 1400px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 374px,
        md: 720px,
        lg: 1060px,
        xl: 1330px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 40px !default;

// END of Grid Options


// Bootstrap file imports
@import "breakpoints/mixins/breakpoints";
@import "grid/bootstrap-grid";
@import "sizing/utilities/sizing";
@import "layout/utilities/spacing";
@import "layout/mixins/visibility";
@import "layout/utilities/visibility";
