.footer { // added automatically by Gulp
	font-family: $fontHeader;

	* {
		vertical-align: top; } }

.footer_nice-font-color {
	color: $colorCyan; }

.footer__contacts {
	display: inline-block; }

.footer__contact {
	margin-bottom: 12px;
	margin-right: 70px;
	display: inline-block;
	white-space: nowrap; }

.footer__buttons {
	display: inline-block; }

.footer__button {
	margin-bottom: 12px;
	display: inline-block;
	margin-right: 70px; }

.footer__nav {
	display: flex;

	li {
		margin-bottom: 10px; } }

.footer__nav-col {
	margin-right: 70px; }

.footer__pronin-text {
	margin-bottom: 4px; }


@include media-breakpoint-down(sm) {
	.footer__nav {
		display: block; } }
