.thumbnail {

	&__wrap {
		position: relative;
		overflow: hidden;
		padding-bottom: 57.9%;

		&:hover,
		&.hover {

			&:after {
				opacity: .6; } }

		&:after {
			@include overlay($colorDarkSea);
			opacity: 0;
			transition: $timeTransitionMid; } }

	&__img {
		position: absolute;
		top: 0;
		width: 100%; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
