// Стили .header для всех внутренних страниц
.header {
	position: fixed;
	top: 0;
	z-index: 20;
	font-size: 15px;
	font-family: $fontHeader;

	.header__top-menu {
		position: relative;
		color: $colorDeepOcean;
		padding: 30px 0 32px;
		transition: $timeTransitionMid;
		z-index: 5;

		&:after {
			position: absolute;
			background-color: $colorBrown;
			top: 0;
			bottom: 0;
			left: -4000px;
			right: -4000px;
			content: '';
			transition: $timeTransitionMid;
			border-bottom: 2px solid rgba($colorDeepOcean, 1); } }

	.header__nav-directions {
		display: none;
		position: absolute;
		left: -30px;
		top: -1000px;
		transform: translateY(-101%);
		background-color: $colorBrown;
		color: $colorDeepOcean;
		padding: 18px 30px 25px;
		transition: transform $timeTransitionMid, top $timeTransitionMid step-end;

		&.active {
			top: 100%;
			transform: translateY(0);
			transition: transform $timeTransitionMid, top $timeTransitionMid step-start; } }

	.header__nav-directions-link {
		cursor: pointer;
		text-decoration: none;
		color: $colorDeepOcean;

		&:hover,
		&:active,
		&:visited {
			color: $colorDeepOcean;
			.header__nav-directions-link-underline {
				background-size: 100% 100%; } }
		.header__nav-directions-link-underline {
			width: calc(100%);
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: background-size $timeTransitionSlow; } }


	.header__bottom-menu {
		position: relative;
		color: $colorBrown;
		background: $colorDeepOcean;
		padding: 26px 0 28px;
		transition: $timeTransitionMid;

		&:after {
			position: absolute;
			background: $colorDeepOcean;
			top: 0;
			bottom: 0;
			left: -4000px;
			right: -4000px;
			content: ''; } }

	.header__bottom-menu-items {
		display: flex; }

	.header__bottom-menu-item {
		margin-right: 40px; }

	.header__top-menu-content,
	.header__bottom-menu-content {
		position: relative;
		z-index: 3;
		display: flex; }

	.header__top-menu-content {
		justify-content: space-between; }

	.header__top-menu-left,
	.header__top-menu-right {
		display: flex; }

	.header__logo-and-menu-cont {
		position: relative;
		display: flex;
		overflow: hidden;
		width: 105px;
		height: 28px;
		margin-right: 45px;
		transition: $timeTransitionMid $timeTransitionMid; }

	.header__logo {
		transition: $timeTransitionMid $timeTransitionMid*2;
		svg {
			path {
				fill: $colorDeepOcean; } } }

	.header__menu {
		position: absolute;
		transform: translateX(-100%);
		padding-right: 25px;
		transition: $timeTransitionMid $timeTransitionMid;
		cursor: pointer;
		text-decoration: none;
		color: $colorDeepOcean;
		&:hover,
		&:active,
		&:visited {
			color: $colorDeepOcean;
			.header__menu-underline {
				background-size: 100% 100%; } }
		.header__menu-underline {
			width: calc(100%);
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: background-size $timeTransitionSlow; }

		&:after {
			position: absolute;
			content: '';
			right: 2px;
			top: 11px;
			transition: $timeTransitionMid;
			background-image: url('../assets/svg/arrow-mini.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			width: 13px;
			height: 6px; }

		&.active {

			&:after {
				transform: rotate(180deg); } } }


	.header__nav-pages {
		ul {
			li {
				margin-right: 30px;
				white-space: nowrap;
				display: inline-block; } } }

	.header__nav-link {
		position: relative;
		text-decoration: none;
		color: $colorDeepOcean;
		&:hover,
		&:active,
		&:visited {
			color: $colorDeepOcean;
			.header__nav-link-underline {
				background-size: 100% 100%; } }
		.header__nav-link-underline {
			width: calc(100%);
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: background-size $timeTransitionSlow; } }

	.header__city {
		margin-right: 40px; }

	.header__phone {
		margin-right: 40px;
		.header__phone-underline {
			width: calc(100%);
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: background-size $timeTransitionSlow; }
		&:hover,
		&:active {
			.header__phone-underline {
				background-size: 100% 100%; } } }

	.header__cta {
		position: relative;
		padding-left: 48px;
		cursor: pointer;
		.header__cta-underline {
			width: calc(100%);
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: background-size $timeTransitionSlow; }
		&:hover,
		&:active {
			.header__cta-underline {
				background-size: 100% 100%; } }
		&:before {
			position: absolute;
			left: 0;
			width: 24px;
			height: 24px;
			border: 4px solid $colorDeepOcean;
			border-radius: 50%;
			content: '';
			transition: border-width $timeTransitionMid, border-color $timeTransitionMid; }

		&:hover {

			&:before {
				border-width: 12px; } } }

	.header__mobile-icons {
		display: flex; }

	.header__mobile-icons-item {
		cursor: pointer;
		margin-left: 24px;
		svg {
			vertical-align: middle;
			path,
			rect {
				transition: $timeTransitionMid;
				fill: $colorDeepOcean; } } } }


// Стили для .header внутренних страниц для состояния scrolled
.header.scrolled {

	.header__top-menu {

		&:after {
			border-bottom: 2px solid rgba($colorDeepOcean, 1); } }

	.header__logo-and-menu-cont {
		width: 165px; }

	.header__logo {
		transform: translateX(-100%);
		transition: $timeTransitionMid $timeTransitionMid; }

	.header__menu {
		transform: translateX(0);
		transition: $timeTransitionMid $timeTransitionMid*2; }

	.header__nav-directions {
		display: block; }

	.header__bottom-menu {
		transform: translateY(-101%); } }

// Стили .header для главной страницы
.index-page {
	.header {

		.header__top-menu {
			color: $colorCyan;
			padding-top: 62px;

			&:after {
				background-color: rgba($colorBrown, .0);
				border-bottom: 2px solid rgba($colorDeepOcean, .0); } }

		.header__logo {
			svg {
				path {
					transition: $timeTransitionMid;
					fill: $colorCyan; } } }
		.header__nav-link {
			color: $colorCyan;
			transition: $timeTransitionMid;
			&:hover,
			&:active,
			&:visited {
				color: $colorCyan; } }
		.header__nav-link-underline,
		.header__phone-underline,
		.header__cta-underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorCyan 2px); }

		.header__bottom-menu {
			display: none; }

		.header__cta {
			&:before {
				border: 4px solid $colorCyan; }

			&:hover {
				&:before {
					border-width: 12px; } } }

		.header__mobile-icons-item {
			svg {
				path,
				rect {
					fill: $colorCyan; } } } } }

// Стили для .header главной страницы для состояния scrolled
.index-page {
	.header.scrolled {

		.header__top-menu {
			padding-top: 30px;
			color: $colorDeepOcean;

			&:after {
				background-color: rgba($colorBrown, 1);
				border-bottom: 2px solid rgba($colorDeepOcean, 1); } }

		.header__logo {
			svg {
				path {
					fill: $colorDeepOcean; } } }

		.header__nav-link {
			color: $colorDeepOcean;
			&:hover,
			&:active,
			&:visited {
				color: $colorDeepOcean; } }
		.header__nav-link-underline,
		.header__phone-underline,
		.header__cta-underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); }

		.header__cta {
			&:before {
				border: 4px solid $colorDeepOcean; }

			&:hover {
				&:before {
					border-width: 12px; } } }

		.header__mobile-icons-item {
			svg {
				path,
				rect {
					fill: $colorDeepOcean; } } } } }


// Адаптив
@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.header {
		.header__bottom-menu {
			display: none; }

		.header__top-menu {
			padding: 20px 0 22px; } }

	// Стили для .header внутренних страниц для состояния scrolled
	.header.scrolled {

		.header__logo {
			transform: translateX(0); }

		.header__menu {
			display: none;
			transform: translateX(-100%); } } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);


@media (max-width: 1320px) {
	.header {
		.header__nav-pages {

			ul {

				li {
					margin-right: 15px; } } }

		.header__logo-and-menu-cont {
			margin-right: 25px; }

		.header__city {
			margin-right: 15px; } } }

@media (max-width: 1200px) {
	.header {

		.header__city {
			display: none !important; }

		.header__bottom-menu-item {
			margin-right: 30px; } } }
