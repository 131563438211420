.side-popup {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: $colorBlueCyan;
	width: 85%;
	z-index: 25;
	padding: {
		top: 125px;
		bottom: 125px;
		left: 250px;
		right: 40px; }
	overflow-y: scroll;
	opacity: 0;
	transform: translateX(100%);
	transition: transform $timeTransitionFast ease, opacity $timeTransitionFast step-end; }

.side-popup__inner {
	max-width: 800px; }

.side-popup_active {
	opacity: 1;
	transform: translateX(0);
	transition: transform $timeTransitionFast ease, opacity $timeTransitionFast step-start; }

.side-popup__close-button {
	position: fixed;
	top: 22px;
	right: 22px;
	width: 30px;
	height: 30px;
	cursor: pointer;
	&:after {
		position: absolute;
		width: 20px;
		height: 2px;
		background-color: $colorDeepOcean;
		transform: rotate(45deg);
		content: "";
		top: 13px;
		left: 4px; }
	&:before {
		position: absolute;
		width: 20px;
		height: 2px;
		background-color: $colorDeepOcean;
		transform: rotate(135deg);
		content: "";
		top: 13px;
		left: 4px; } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.side-popup {
		width: auto;
		left: 0;
		padding: {
			left: 40px;
			right: 40px; } }

	.side-popup__inner {
		max-width: none; } }


@include media-breakpoint-down(sm) {
	.side-popup {
		padding: {
			left: 20px;
			right: 20px; } } }


@include media-breakpoint-down(xs);
