.mini-header-with-ava { // added automatically by Gulp
	display: flex;
	align-items: center; }

.mini-header-with-ava__img-cont {
	flex-shrink: 0;
	display: inline-block;
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 40px; }

.mini-header-with-ava__img {
	width: 100%; }

.mini-header-with-ava__text {
	font-family: $fontHeader;
	color: $colorDeepOcean;
	font-size: 20px;
	line-height: 26px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.mini-header-with-ava {
		margin-bottom: 30px; } }

@include media-breakpoint-down(sm) {
	.mini-header-with-ava {
		align-items: flex-start;
		flex-direction: column; }
	.mini-header-with-ava__img-cont {
		margin-bottom: 20px; } }

@include media-breakpoint-down(xs);
