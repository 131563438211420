.gallery {
	display: flex;
	flex-wrap: wrap; }

.gallery__items-wrap {}

.gallery__item {
	overflow: hidden;
	margin-bottom: 40px; }

.gallery__img {
	display: block;
	width: 100%; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
