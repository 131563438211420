.action { // added automatically by Gulp
	color: $colorBrown;
	font-family: $fontHeader;
	font-size: 20px;
	align-items: center;
	padding: {
		top: 30px;
		bottom: 30px; } }

.action__description {}

.action__cta {
	text-align: right; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.action {
		padding: {
			top: 40px;
			bottom: 40px; } }

	.action__description {
		padding-bottom: 30px; }
	.action__cta {
		text-align: left; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
