.our-clients-section { // added automatically by Gulp
	background-color: $colorDeepOcean;
	color: $colorBrown; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
