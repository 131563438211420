.list {}


.list_flex {
	display: flex;
	flex-wrap: wrap; }

.list_two-items-in-row {
	width: 66.6666666%; }

.list_ordered {
	list-style: decimal inside; }


.list__item {}


.list__item_in-row {
	width: 50%;
	padding-left: $grid-gutter-width / 2;
	padding-right: $grid-gutter-width / 2;
	margin-bottom: 40px; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.list_two-items-in-row {
		width: 100%; } }

@include media-breakpoint-down(sm) {
	.list__item_in-row {
		width: 100%;
		margin-bottom: 30px; } }


@include media-breakpoint-down(xs);
