.post-preview {
	margin-bottom: 40px; }

.post-preview__col {
	display: flex;
	flex-direction: column;
	justify-content: center; }

.post-preview__thumpnail {}

.post-preview__hashtags {
	margin-bottom: 24px; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.post-preview {
		margin-bottom: 30px; }

	.post-preview__hashtag {
		margin-bottom: 20px; } }

@include media-breakpoint-down(sm) {
	.post-preview {
		margin-bottom: 40px; }

	.post-preview__thumpnail {
		margin-bottom: 20px; }

	.post-preview__hashtag {
		margin-bottom: 10px; } }

@include media-breakpoint-down(xs);
