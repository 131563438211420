.text-items { // added automatically by Gulp
	margin-bottom: 55px; }

.text-items_header-font {
	font-family: $fontHeader; }

.text-items__item {
	margin-bottom: 25px;
	font-size: 20px;
	line-height: 26px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
