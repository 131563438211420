.important-text-items {
	font-family: $fontHeader;
	font-size: 20px;
	line-height: 26px; }

.important-text-items__item {
	margin-bottom: 40px; }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.important-text-items__item {
		margin-bottom: 30px; } }


@include media-breakpoint-down(sm) {
	.important-text-items__item {
		margin-bottom: 40px; } }


@include media-breakpoint-down(xs);
