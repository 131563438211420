.index-page {

	.top-menu {
		color: $colorCyan;
		background-color: transparent;

		&.scrolled {

			.top-nav__cta {

				&:hover {

					&:before {
						border: 12px solid $colorDeepOcean; } } } }

		.top-menu__cont {
			padding-top: 62px; }

		.top-menu__logo {

			svg {

				path {
					fill: $colorCyan; } } }

		.top-nav__cta {

			&:before {
				border: 3px solid $colorCyan; }

			&:hover {

				&:before {
					border: 12px solid $colorCyan; } }

			.button__underline {
				color: $colorCyan;
				background-image: linear-gradient(transparent calc(100% - 2px), $colorCyan 2px); } }

		.top-menu__nav-link {
			color: $colorCyan; }

		.link__underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorCyan 2px); }

		.button__underline {
			color: $colorCyan; }

		.top-menu__nav-link {
			color: $colorCyan;

			.link__underline {
				background-image: linear-gradient(transparent calc(100% - 2px), $colorCyan 2px); } }

		.top-nav__phone {

			.link__underline {
				color: $colorCyan; } }

		.top-menu__nav-pages {

			ul {

				li {

					a {

						.link__underline {
							color: $colorCyan; } } } } } }

	.top-nav__city {
		color: $colorCyan; } }



.top-menu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	color: $colorDeepOcean;
	font-family: $fontHeader;
	font-size: 15px;
	line-height: 17px;
	align-items: center;
	opacity: 1;
	transform: translateX(0);
	border-bottom: 2px solid transparent;
	transition: $timeTransitionMid;
	background-color: $colorBrown;

	&.disappeared {
		opacity: 0;
		transform: translateY(-20px); }

	&.scrolled {
		background-color: $colorBrown;
		color: $colorDeepOcean;
		border-bottom: 2px solid $colorDeepOcean;

		.top-menu__cont {
			padding-top: 30px; }

		.top-nav__city {
			color: $colorDeepOcean; }

		.link__underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); }

		.top-menu__nav-pages {

			ul {

				li {

					a {
						transition: $timeTransitionMid;

						.link__underline {
							color: $colorDeepOcean;
							background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); } } } } }

		.top-nav__phone {

			.link__underline {
				color: $colorDeepOcean; } }

		.top-nav__cta {

			&:before {
				border: 3px solid $colorDeepOcean; }

			&:hover {

				&:before {
					border-width: 12px; } }

			.button__underline {
				background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
				color: $colorDeepOcean; } }

		.top-menu__nav-directions-header {
			color: $colorDeepOcean;
			transform: translateX(0);
			transition: $timeTransitionMid $timeTransitionMid*2; }

		.top-menu__logo {
			transform: translateX(-100%);
			transition: $timeTransitionMid $timeTransitionMid; }

		.top-memu__logo-directions-cont {
			width: 165px; }

		.top-menu__nav-directions-overlay {
			background-color: $colorBrown;
			border-bottom: 2px solid $colorDeepOcean;
			transform: translateX(0); }

		.top-menu__nav-directions {
			top: calc(100% + 2px);
			opacity: 1; }

		.top-menu__logo {

			svg {

				path {
					fill: $colorDeepOcean; } } }

		.top-menu__mobile-icons__item {

			&.top-menu__mobile-icons__item_cta {

				svg {

					path {
						fill: $colorDeepOcean; } } }

			&.top-menu__mobile-icons__item_call-to {

				svg {

					path {
						fill: $colorDeepOcean; } } }

			&.top-menu__mobile-icons__item_burger {

				svg {

					rect {
						fill: $colorDeepOcean; } } } } }

	.top-menu__nav-link {
		color: $colorDeepOcean;

		.link__underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); } } }

.top-menu__nav-directions-overlay {
	z-index: 3;
	background-color: transparent;
	transition: background-color $timeTransitionMid, border-bottom $timeTransitionMid;
	position: absolute;
	top: -50px;
	bottom: -2px;
	left: -1000px;
	right: -1000px;
	border-bottom: 2px solid $colorDeepOcean;
	transform: translateX(-200%); }

.top-memu__logo-directions-cont {
	transition: $timeTransitionMid $timeTransitionMid;
	display: flex;
	align-items: center;
	overflow: hidden;
	width: 105px;
	height: 28px;
	position: relative;
	margin-right: 45px; }

.top-menu__nav-directions-header {
	position: absolute;
	left: 0;
	transition: $timeTransitionMid $timeTransitionMid;
	color: $colorDeepOcean;
	padding-right: 25px;
	transform: translateX(-100%);

	&:after {
		position: absolute;
		content: '';
		right: 2px;
		top: 6px;
		transition: $timeTransitionMid;
		background-image: url('../assets/svg/arrow-mini.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 13px;
		height: 6px; }

	&.active {

		&:after {
			transform: rotate(180deg); } } }


.top-menu__nav-directions {
	z-index: 2;
	transform: translateY(-104%);
	position: absolute;
	left: -30px;
	top: -400px;
	opacity: 0;
	transition: transform $timeTransitionMid, top $timeTransitionMid $timeTransitionMid;

	&.active {
		transform: translateY(0); } }

.top-menu__nav-directions-container {
	padding-top: 18px;
	padding-bottom: 25px;
	padding-left: 30px;
	padding-right: 30px;
	background-color: $colorBrown;
	color: $colorDeepOcean;

	.top-menu__nav-directions-item {
		margin-bottom: 10px; }

	.top-menu__nav-directions-link {
		color: $colorDeepOcean; } }

.top-menu__cont {
	position: relative;
	z-index: 4;
	display: flex;
	padding: 30px 0 32px;
	justify-content: space-between;
	align-items: center;
	transition: $timeTransitionMid; }

.top-menu__left-side {
	z-index: 6;
	display: flex;
	justify-content: space-between;
	align-items: center; }

.top-menu__right-side {
	z-index: 6;
	display: flex;
	justify-content: space-between;
	align-items: center; }

.top-menu__logo {
	margin-right: 45px;
	transition: $timeTransitionMid $timeTransitionMid*2;

	svg {

		path {
			transition: $timeTransitionMid;
			fill: $colorDeepOcean; } } }

.top-menu__nav-pages {

	ul {

		li {
			margin-right: 30px;
			white-space: nowrap;
			display: inline-block;

			a {

				.link__underline {
					color: $colorDeepOcean;
					transition: background-size $timeTransitionSlow, color $timeTransitionMid; } } } } }

.top-menu__nav-business {}

.top-nav__city {
	margin-right: 40px;
	color: $colorDeepOcean;
	transition: $timeTransitionMid; }

.top-nav__phone {
	white-space: nowrap;
	display: inline-block;
	margin-right: 40px;
	transition: $timeTransitionMid;

	.link__underline {
		color: $colorDeepOcean; }

	&:hover,
	&:active,
	&:visited {
		color: $colorDeepOcean; }

	.link__underline {
		transition: background-size $timeTransitionSlow, color $timeTransitionMid;
		background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); } }

.top-nav__cta {

	&:before {
		transition: $timeTransitionMid;
		border: 3px solid $colorDeepOcean; }

	&:hover {

		&:before {
			border: 3px solid $colorDeepOcean;
			border-width: 12px; } }

	.button__underline {
		color: $colorDeepOcean;
		transition: background-size $timeTransitionSlow, color $timeTransitionMid;
		background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); } }

.top-menu__mobile-icons {
	display: flex;
	align-items: center; }

.top-menu__mobile-icons__item {
	cursor: pointer;
	margin-left: 24px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;

	&.top-menu__mobile-icons__item_cta {
		width: 34px;
		height: 28px;

		svg {

			path {
				transition: $timeTransitionMid;
				fill: $colorCyan; } } }

	&.top-menu__mobile-icons__item_call-to {
		width: 30px;
		height: 30px;

		svg {

			path {
				transition: $timeTransitionMid;
				fill: $colorCyan; } } }

	&.top-menu__mobile-icons__item_burger {
		width: 34px;
		height: 27px;

		svg {

			rect {
				transition: $timeTransitionMid;
				fill: $colorCyan; } } } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.top-menu {

		&.scrolled {

			.top-menu__logo {
				transform: translateX(0); } } } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);


@media (max-width: 1320px) {

	.top-menu__nav-pages {

		ul {

			li {
				margin-right: 15px; } } }

	.top-memu__logo-directions-cont {
		margin-right: 25px; }

	.top-nav__city {
		margin-right: 15px; } }


@media (max-width: 1200px) {

	.top-nav__city {
		display: none !important; } }
