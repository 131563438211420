.one-click-help {} // added automatically by Gulp

.one-click-help__item {
	cursor: pointer;
	margin-bottom: 30px;

	&:hover {

		.one-click-help__img-cont {

			&:after {
				opacity: .6; } }

		.one-click-help__title {

			&:after {
				transform: translateX(3px); } }

		.one-click-help__title-underline {
			background-size: 100% 100%; } } }

.one-click-help__img-cont {
	position: relative;
	width: 100%;
	height: 163px;
	overflow: hidden;
	font-size: 0;
	margin-bottom: 40px;
	cursor: pointer;

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: '';
		background-color: $colorDarkSea;
		opacity: 0;
		transition: .7s; } }

.one-click-help__img {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%); }

.one-click-help__title {
	position: relative;
	font-family: $fontHeader;
	margin-bottom: 50px;
	cursor: pointer;
	color: $colorDeepOcean;

	&:hover {
		.one-click-help__title-underline {
			background-size: 100% 100%; } }

	&:after {
		position: absolute;
		left: 0;
		bottom: -38px;
		width: 19px;
		height: 19px;
		content: '';
		background-image: url('../assets/icons/arrow-with-base.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transition: .7s; } }

.one-click-help__title-underline {
	width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size 1s; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.one-click-help {
		padding-top: 80px; }

	.one-click-help__img-cont {
		margin-bottom: 20px; } }

@include media-breakpoint-down(sm) {
	.one-click-help {
		padding-top: 60px; }

	.one-click-help__img-cont {
		height: 203px;
		margin-bottom: 30px; } }

@include media-breakpoint-down(xs) {
	.one-click-help__img-cont {
		height: 160px; } }

