.breadcrumbs {
	color: $colorDeepOcean;
	display: inline-block;

	a {
		position: relative;
		margin-right: 16px;
		border-bottom: 2px solid $colorDeepOcean;

		&:after {
			position: absolute;
			right: -14px;
			content: '/'; }

		&:hover,
		&:active {
			color: $colorDarkSea;
			border-bottom: 2px solid $colorDarkSea; } } }

.breadcrumbs_margin-right_lg {
	margin-right: 70px; }

.breadcrumbs_margin-bottom_md {
	margin-bottom: 25px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
