.button { // added automatically by Gulp
	position: relative;
	display: inline-block;
	border: none;
	background-color: transparent;
	white-space: nowrap;
	font-family: $fontHeader;
	font-size: 20px;
	line-height: 24px;
	color: $colorBlueCyan;
	padding-left: 48px;
	text-decoration: none;
	cursor: pointer;

	&:before {
		position: absolute;
		left: 0;
		width: 24px;
		height: 24px;
		border: 4px solid $colorBlueCyan;
		border-radius: 50%;
		content: '';
		transition: border-width 0.7s; }

	&:hover,
	&:visited,
	&:active {
		color: $colorBlueCyan; }

	&:hover {

		&:before {
			border-width: 12px; }

		.button__underline {
			background-size: 100% 100%; } } }

.button_color_dark {
	color: $colorDeepOcean;

	&:hover,
	&:visited,
	&:active {
		color: $colorDeepOcean; }

	&:before {
		border: 4px solid $colorDeepOcean; } }

.button_st-mt {
	margin-top: 50px; }

.button_long {
	white-space: normal;
	max-width: 400px;
	text-align: left; }

.button__underline {
	width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), $colorBlueCyan 2px);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size 1s; }


.button_inverted {
	color: $colorBrown;

	&:before {
		border-color: $colorBrown; }

	&:hover,
	&:visited,
	&:active {
		color: $colorBrown; }

	.button__underline {
		background-image: linear-gradient(transparent calc(100% - 2px), $colorBrown 3px); } }


.button_small {
	color: $colorCyan;
	font-size: 15px;
	line-height: 24px;

	&:hover,
	&:visited,
	&:active {
		color: $colorCyan; }


	&:before {
		border: 3px solid $colorCyan; }

	.button__underline {
		background-image: linear-gradient(transparent calc(100% - 2px), $colorCyan 3px); } }


.button.button_arrow {
	padding-left: 0;
	padding-right: 22px;
	line-height: 18px;
	font-size: 15px;

	&:before {
		position: absolute;
		right: 0;
		left: auto;
		width: 11px;
		height: 18px;
		border: none;
		content: '';
		background-image: url('../assets/icons/arrow.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: .7s; }

	&:hover {

		&:before {
			transform: translateX(3px); } } }


@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.button_st-mt {
		margin-top: 40px; } }

@include media-breakpoint-down(sm) {

	.button_st-mt {
		margin-top: 30px; }

	.button_long {
		font-size: 18px; }

	.button_one-click-help-block {
		font-size: 18px;
		white-space: normal;
		max-width: 400px;
		text-align: left; } }


@include media-breakpoint-down(xs) {
	.button {
		font-size: 16px; } }
