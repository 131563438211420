.cards {} // added automatically by Gulp

.cards__item {
	margin-bottom: 40px; }

.cards__img-cont {
	position: relative;
	width: 100%;
	height: 163px;
	overflow: hidden;
	font-size: 0;
	margin-bottom: 40px; }

.cards__img {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%); }

.cards__title {
	font-family: $fontHeader;
	font-size: 20px;
	line-height: 26px;
	min-height: 52px;
	margin-bottom: 15px; }

.cards__description {
	margin-bottom: 0;
	position: relative; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.cards__img-cont {
		margin-bottom: 30px; } }


@include media-breakpoint-down(sm) {
	.cards__img-cont {
		margin-bottom: 40px; } }


@include media-breakpoint-down(xs);
