.link { // added automatically by Gulp
	position: relative;
	font-family: $fontHeader;
	color: $colorBrown;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:active,
	&:visited,
	&.hover {
		color: $colorBrown;

		.link__underline {
			background-size: 100% 100%; } } }

.link_in-main-page-big-business-nav {
	width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), $colorBrown 2px);
	background-size: 100% 100%;
	font-size: 32px;
	line-height: 40px;

	&:hover {
		background-image: linear-gradient(transparent calc(100% - 2px), $colorDarkBrown 2px);
		color: $colorDarkBrown; } }

.link__underline {
	width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), $colorBrown 2px);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size 1s; }


// Модификаторы цвета ссылки

.link {

	&.link_cyan {
		color: $colorCyan;

		&:hover,
		&:active,
		&:visited {
			color: $colorCyan; }

		.link__underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorCyan 2px); } }

	&.link_color_deep-ocean {
		color: $colorDeepOcean;

		&:hover,
		&:active,
		&:visited {
			color: $colorDeepOcean; }

		.link__underline {
			background-image: linear-gradient(transparent calc(100% - 2px), $colorDeepOcean 2px); } } }

// /Модификаторы цвета ссылки


// Модификаторы размера шрифта

.link_fz_md {
	font-size: 20px;
	line-height: 26px; }

// /Модификаторы размера шрифта


@include media-breakpoint-down(md) {
	.link_in-main-page-big-business-nav {} }

@include media-breakpoint-down(sm) {
	.link_in-main-page-big-business-nav {
		font-size: 24px;
		line-height: 32px; } }

@include media-breakpoint-down(xs) {
	.link_in-main-page-big-business-nav {
		font-size: 20px;
		line-height: 26px; } }



