.breadcrumbs-and-tags-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.breadcrumbs-and-tags-wrap {
		margin-bottom: 50px; } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
