.insta-block {}

.insta-block__cont {
	display: flex;
	justify-content: space-between; }

$grid-gutter-width-x5: $grid-gutter-width * 5;
.insta-block__item {
	position: relative;
	overflow: hidden;
	width: calc((100% - #{$grid-gutter-width-x5}) / 6);
	padding-bottom: calc((100% - #{$grid-gutter-width-x5}) / 6); }

.insta-block__img {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0; }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
	.insta-block {
		margin: 0 -20px; }

	.insta-block__item {
		width: 193px;
		height: 193px; } }

@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs);
