.form {

	label {
		transition: $timeTransitionMid; }

	&.form_max-w700 {
		max-width: 700px; }

	&.form_standart {
		font-family: $fontHeader;
		color: $colorDeepOcean;
		font-size: 32px;
		line-height: 40px;

		input, textarea {
			position: relative;
			z-index: 5;
			font-family: $fontHeader;
			color: $colorDeepOcean;
			border-bottom: 5px solid $colorDeepOcean; }

		input {
			height: 50px;
			font-size: 32px;
			line-height: 40px; }

		textarea {
			min-height: 48px;
			font-size: 32px;
			line-height: 40px; }

		label {
			cursor: text;
			position: absolute;
			bottom: 6px;
			left: 2px; }

		input:focus,
		textarea:focus {

			+ label {
				color: $colorDarkBrown; } }

		.form__input-container {
			position: relative;
			margin-bottom: 30px; }

		.form__submit-container {
			position: relative;
			white-space: nowrap;

			.form__submit-preloader {
				display: inline-block;
				height: 10px;
				width: 37px;
				background-image: url('../assets/svg/dots-preloader.svg');
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat; } }

		.form__error-message {
			position: absolute;
			left: 3px;
			bottom: -25px;
			margin: 0;
			color: $colorRed; }

		.form__error-message {

			p {
				margin: 0; } }


		.form__input-container-underline-wrap {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 5px;
			content: '';
			overflow: hidden;

			&:before,
			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 5px;
				content: '';
				transition: transform $timeTransitionSlow;
				transform: translateX(-100%);
				z-index: 7; }

			&:after {
				background-color: $colorRed; }

			&:before {
				background-color: $colorGreenCyan; } } }


	&.form_light-bg {

		&.form_standart {

			.form__input-container {

				&.form__input-container_input-not-valid {

					input, textarea, label {
						color: $colorRed; }

					.form__input-container-underline-wrap {

						&:after {
							transform: translateX(0%); } } }

				&.form__input-container_input-valid {

					input, textarea, label {
						color: $colorGreenCyan; }

					.form__input-container-underline-wrap {

						&:before {
							transform: translateX(0%); } } } } } } }

@include media-breakpoint-down(xl);


@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);


@include media-breakpoint-down(sm) {
	.form {

		&.form_standart {
			font-size: 24px;
			line-height: 32px; } } }


@include media-breakpoint-down(xs) {
	.form {

		&.form_standart {
			font-size: 20px;
			line-height: 26px; } } }
